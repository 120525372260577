<template>
  <div v-if="mode === 'edit'" class="fr-setting">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <SwitchSetter v-model="paramSrc" :data="frConfig.paramSource" :enabled="false" />
      <div class="line"></div>
      <SwitchSetter v-model="setting.channel[0].fr.isEnabled" :data="frConfig.isEnabled" :enabled="canSetting" />
      <SliderSetter v-model="setting.channel[0].fr.faceSizeRatio" :data="frConfig.faceSizeRatio" :enabled="canSetting"  />
      <div class="expert-mode" @click="isEditExpertMode = !isEditExpertMode">
        <img v-if="isEditExpertMode" src="@/assets/icons/Arrow_down.svg" alt="">
        <img v-else class="arrow-right" src="@/assets/icons/Arrow_down.svg" alt="">
        <span>{{ $t('ai_expert_mode')/*專家模式*/ }}</span>
      </div>
      <template v-if="isEditExpertMode">
        <SwitchSetter v-model="setting.channel[0].fr.detectMode" :data="frConfig.detectMode" :enabled="canSetting" />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_full_face_mode')/*全臉模式*/ }}</div>
        <SliderSetter v-model="setting.channel[0].fr.eventScore" :data="frConfig.eventScore" :enabled="canSetting" />
        <SliderSetter v-model="setting.channel[0].fr.candidateScore" :data="frConfig.candidateScore" :enabled="canSetting" />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_mask_mode')/*口罩模式*/ }}</div>
        <SliderSetter v-model="setting.channel[0].fr.eventScoreWithMask" :data="frConfig.eventScoreWithMask" :enabled="canSetting" />
        <SliderSetter v-model="setting.channel[0].fr.candidateScoreWithMask" :data="frConfig.candidateScoreWithMask" :enabled="canSetting" />
        <div class="line"></div>
        <SliderSetter v-model="setting.channel[0].fr.detectThresholdLevel" :data="frConfig.detectThresholdLevel" :enabled="canSetting" />
        <SwitchSetter v-model="setting.deviceConfig.fr.eventPostMode" :data="frConfig.eventPostMode" :enabled="canSetting" />
        <SelectSetter v-model="notifyFilterMode" :data="frConfig.notifyFilterMode" :enabled="canSetting" />
        <SliderSetter v-model="setting.deviceConfig.fr.eventDuration" :data="frConfig.eventDuration" :enabled="canSetting" />
        <SliderSetter v-model="setting.deviceConfig.fr.repostDataStoreLimit" :data="frConfig.repostDataStoreLimit" :enabled="canSetting" />
      </template>
    </div>
  </div>
  <div v-else class="fr-setting view">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <ConfigItem v-model="currDefaultSetting" :data="frConfig.paramSource" :showTooltip="false" />
      <ConfigItem v-model="setting.channel[0].fr.isEnabled" :data="frConfig.isEnabled" :showTooltip="false" />
      <ConfigItem v-model="setting.channel[0].fr.faceSizeRatio" :data="frConfig.faceSizeRatio" />
      <div class="expert-mode" @click="isViewExpertMode = !isViewExpertMode">
        <img v-if="isViewExpertMode" src="@/assets/icons/Arrow_down.svg" alt="">
        <img v-else class="arrow-right" src="@/assets/icons/Arrow_down.svg" alt="">
        <span>{{ $t('ai_expert_mode')/*專家模式*/ }}</span>
      </div>
      <template v-if="isViewExpertMode">
        <ConfigItem v-model="setting.channel[0].fr.detectMode" :data="frConfig.detectMode" />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_full_face_mode')/*全臉模式*/ }}</div>
        <ConfigItem v-model="setting.channel[0].fr.eventScore" :data="frConfig.eventScore" />
        <ConfigItem v-model="setting.channel[0].fr.candidateScore" :data="frConfig.candidateScore" />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_mask_mode')/*口罩模式*/ }}</div>
        <ConfigItem v-model="setting.channel[0].fr.eventScoreWithMask" :data="frConfig.eventScoreWithMask" />
        <ConfigItem v-model="setting.channel[0].fr.candidateScoreWithMask" :data="frConfig.candidateScoreWithMask" />
        <div class="line"></div>
        <ConfigItem v-model="setting.channel[0].fr.detectThresholdLevel" :data="frConfig.detectThresholdLevel" />
        <ConfigItem v-model="setting.deviceConfig.fr.eventPostMode" :data="frConfig.eventPostMode" />
        <ConfigItem v-model="notifyFilterMode" :data="frConfig.notifyFilterMode" />
        <ConfigItem v-model="setting.deviceConfig.fr.eventDuration" :data="frConfig.eventDuration" />
        <ConfigItem v-model="setting.deviceConfig.fr.repostDataStoreLimit" :data="frConfig.repostDataStoreLimit" />
      </template>
      <div class="line"></div>
      <div class="time">
        <span>{{ $t('ai_set_time')/*設定時間*/ }}</span>
        <span>{{ formatTime(currentSetting.updateTime) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { frConfig } from '@/config/configFr.js'
import moment from 'moment'

export default {
  name: 'FrSetting',
  components: {
    SwitchSetter: () => import('@/components/AiBox/base/SwitchSetter.vue'),
    SliderSetter: () => import('@/components/AiBox/base/SliderSetter.vue'),
    SelectSetter: () => import('@/components/AiBox/base/SelectSetter.vue'),
    ConfigItem: () => import('@/components/AiBox/base/ConfigItem.vue'),
  },
  data() {
    return {
      frConfig,
      globalConfig: {},
      userConfig: {},
      isEditExpertMode: false,
      isViewExpertMode: false,
    }
  },
  computed: {
    ...mapGetters(['timezone']),
    ...mapState('aiboxFr', [
      'mode', 
      'currDefaultSetting', 
      'defaultSetting', 
      'isEditRoi', 
      'userSetting', 
      'faceSizeRatio',
      'frNotifyFilterMode',
    ]),
    ...mapGetters('aiboxFr', ['currentSetting']),
    paramSrc: {
      get() {
        return this.currDefaultSetting
      },
      set(val) {
        this.onUpdateCurrDefaultSetting(val)
      },
    },
    canSetting() {
      // defaultSetting = 0: userSetting 設備客製/可編輯, 1: defaultSetting 系統預設/不可編輯
      return this.currDefaultSetting === 0 && !this.isEditRoi
    },
    setting: {
      get() {
        return this.currentSetting.setting
      },
      set(data) {
        this.updateSetting(data)
      },
    },
    config() {
      return this.currDefaultSetting === 1 ? this.globalConfig : this.userConfig
    },
    scrollFaceRatio() {
      return this.isConfigReady ? 
        this.config.setting.channel[0].fr.faceSizeRatio : null
    },
    notifyFilterMode: {
      get() {
        return this.frNotifyFilterMode
      },
      set(val) {
        this.updateFrNotifyFilterMode(val)
      },
    },
  },
  watch: {
    faceSizeRatio(val) {
      if (this.isConfigReady)
        this.config.setting.channel[0].fr.faceSizeRatio = val
    },
    scrollFaceRatio(val) {
      this.updateFaceSizeRatio(val)
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('aiboxFr', [
      'updateSetting', 
      'updateFaceSizeRatio', 
      'updateFrNotifyFilterMode',
    ]),
    ...mapActions('aiboxFr', ['onUpdateCurrDefaultSetting']),
    formatTime(dateTime) {
      return moment(dateTime).tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.fr-setting {
  width: 100%;
  height: 100%;

  .title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: normal;
    color: #ffffff;
    margin-bottom: 8px;
    img {
      margin-right: 8px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(14%) saturate(7493%) hue-rotate(180deg) brightness(115%) contrast(92%);
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    height: calc(100% - 44px);
    padding: 12px;
    box-sizing: border-box;
    overflow-y: overlay;
    background: #151B35f5;
    border-radius: 8px;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #ffffff80;
    }
    .expert-mode {
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 28px;
      color: #ffffff;
      cursor: pointer;
      img {
        margin-right: 12px;
        &.arrow-right {
          transform: rotate(-90deg);
        }
      }
    }
    .mode {
      color:#ffffff80;
    }
  }

  &.view {
    .title {
      color: #FFE99F;
      font-weight: 500;
      img {
        filter: none;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      background: #4A5C7833;

      .config-item {
        display: flex;
        align-items: center;
        column-gap: 12px;
        .info {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .tip {
          width: 24px;
          height: 24px;
        }
        .label {
          font-size: 24px;
          color: #ffffff;
        }
        .value {
          color: #ffffff;
        }
        .unit {
          color: #ffffff80;
        }
      }

      .time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff80;
        font-size: 20px;
      }
    }
  }
}
</style>